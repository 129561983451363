const format = require("date-fns/format");

export default state => {
  const today = new Date();
  const year = format(today, "YYYY");
  const month = format(today, "MMMM");
  const day = format(today, "Do");
  const fullDate = format(today, "h:mm a, dddd, M/D/YYYY");
  state.infoForServer.day = day;
  state.infoForServer.year = year;
  state.infoForServer.month = month;
  state.infoForServer.orderType = state.order.orderType;
  state.infoForServer.fullDate = fullDate;
  return state;
};
