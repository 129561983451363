import ClearEverything from "../actions/clearEverything";
import { buildNewOrderItems } from "./customOrder";

export const mapStateToProps = state => {
  const stateCopy = JSON.parse(JSON.stringify(state));
  stateCopy.order.items = buildNewOrderItems(stateCopy);
  return {
    state: stateCopy,
    orderLen: stateCopy.order.items.length,
  };
};

export const mapDispatchToProps = dispatch => ({
  clearEverything: () => {
    dispatch(ClearEverything());
  },
});
