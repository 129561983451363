import React, { Component } from "react";
import { connect } from "react-redux";
import firebase from "../firebaseInit";
import Layout from "../components/layout/layout";
import { openReceipt, processOrder } from "../pdfmake";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../state/mapStateAndDispatch/final";
import NoItemsInCart from "../components/layout/noItemsInCart";
import "../styles/buttons.css";
import processState from "../processState";

import "firebase/functions";

class final extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cleared: false,
    };
    this.handleClearPage = this.handleClearPage.bind(this);
  }

  async componentDidMount() {
    const { state, location } = this.props;
    processState(state);
    let fromReview = false;
    try {
      ({ fromReview } = location.state);
    } catch {
      // pass
    }
    if (fromReview === true) {
      this.setState({
        fromReview,
      });
    }
    if (state.order.items.length !== 0 && fromReview === true) {
      const fbFunc = firebase.app().functions("us-central1");
      const getOrderNumber = fbFunc.httpsCallable("getOrderNumber");
      const generateReceipt = fbFunc.httpsCallable("generateRegularReceipt");
      const { infoForServer } = state;
      try {
        const res = await getOrderNumber(infoForServer);
        state.order.orderNumber = res.data.orderNumber;
        await processOrder(state, generateReceipt);
        this.setState({
          orderSuccess: "Success",
        });
      } catch (error) {
        this.setState({
          orderSuccess: "Failure",
        });
      } finally {
        this.handleClearPage(state);
      }
    }
  }

  handleClearPage(state) {
    const { clearEverything } = this.props;
    clearEverything();
    this.setState({
      cleared: true,
      state,
    });
  }

  checkOrderSuccess() {
    const { cleared, orderSuccess } = this.state;
    if (cleared === true) {
      if (orderSuccess === "Success") {
        return 1;
      }
      return 0;
    }
    return false;
  }

  checkProcessingOrBlank() {
    const { fromReview, cleared } = this.state;
    if (cleared === false) {
      if (fromReview === true) {
        return 1;
      }
      return 0;
    }
    return false;
  }

  render() {
    const { state } = this.state;
    return (
      <Layout>
        <div>
          {this.checkOrderSuccess() === 1 && (
            <div
              className="container"
              style={{
                height: "75vh",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                textAlign: "center",
                alignItems: "center",
                backgroundColor: "rgb(252,252,252)",
                marginBottom: -8,
              }}
            >
              <h2>ORDER SUCCESSFULLY PLACED!</h2>
              <h2>THANK YOU FOR YOUR ORDER!</h2>
              <br />
              <a onClick={() => openReceipt(state)} className="bigRedButton">
                View Receipt
              </a>
            </div>
          )}
        </div>
        {this.checkOrderSuccess() === 0 && (
          <div>
            <div
              style={{ backgroundColor: "rgb(252,252,252)", marginBottom: -8 }}
            >
              <br />
              <div
                style={{
                  height: "66vh",
                  margin: "auto",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <h2 className="centerText">ORDER FAILED!</h2>
                <h2 className="centerText">PLEASE TRY AGAIN OR CALL US!</h2>
              </div>
            </div>
          </div>
        )}
        {this.checkProcessingOrBlank() === 1 && (
          <div
            style={{ backgroundColor: "rgb(252,252,252)", marginBottom: -8 }}
          >
            <br />
            <div
              style={{
                transform: "translateY(-1.11%)",
                height: "77vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <h1>Processing Order...</h1>
            </div>
          </div>
        )}
        {this.checkProcessingOrBlank() === 0 && <NoItemsInCart />}
      </Layout>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(final);
