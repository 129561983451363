import pdfMake from "pdfmake-lite/build/pdfmake";
import pdfFonts from "pdfmake-lite/build/vfs_fonts";
import returnSauceName from "./components/cart/orderList/returnSauceName";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function buildTableBody(data, columns) {
  const body = [];
  body.push(columns);
  data.forEach(entry => {
    const totalPrice = (
      parseFloat(entry.price) +
      (parseFloat(entry.rnPrice) || 0) +
      (parseFloat(entry.meatPrice) || 0) +
      parseFloat(entry.sauces.eelSauce.price) +
      parseFloat(entry.sauces.spicyMayo.price) +
      parseFloat(entry.sauces.teriyakiSauce.price) +
      parseFloat(entry.sauces.bourbonSauce.price) +
      parseFloat(entry.sauces.sweetSourSauce.price) +
      parseFloat(entry.sauces.dumplingSauce.price)
    ).toFixed(2);

    let rnChoice;
    try {
      rnChoice = entry.rnChoice;
      if (
        (rnChoice.includes("Lo Mein") &&
          rnChoice.includes("Veggie") === false) ||
        rnChoice === "House Fried Rice"
      ) {
        rnChoice = `${rnChoice} (+$4.50)`;
      } else if (
        !rnChoice ||
        rnChoice === "White Rice" ||
        rnChoice === "Fried Rice" ||
        rnChoice === "Plain Fried Rice"
      ) {
        rnChoice = `${rnChoice}`;
      } else {
        rnChoice = `${rnChoice} (+$3.00)`;
      }
    } catch (e) {
      // pass
    }

    let meatChoice;
    try {
      meatChoice = entry.meatChoice;
      if (meatChoice.includes("Double")) {
        meatChoice = `${meatChoice} (+$4.00)`;
      } else if (meatChoice.includes("Extra")) {
        meatChoice = `${meatChoice} (+$2.00)`;
      } else {
        meatChoice = "";
      }
    } catch (e) {
      // pass
    }

    const dataRow = [
      `\n${entry.name === "Pork Egg Roll" ? "Egg Roll" : entry.name}` +
        `${
          entry.sideChoice
            ? `\nSide: ${
                entry.sideChoice === "Pork Egg Roll"
                  ? "Egg Roll"
                  : entry.sideChoice
              }`
            : ""
        }` +
        `${entry.requests ? `\nRequests: ${entry.requests}` : ""}` +
        `${rnChoice ? `\n${rnChoice}` : ""}` +
        `${meatChoice ? `\n${meatChoice}` : ""}` +
        `${
          Object.keys(entry.sauces).filter(key => entry.sauces[key].number > 0)
            .length > 0
            ? `\nSauces:${Object.keys(entry.sauces)
                .filter(key => entry.sauces[key].number > 0)
                .map(
                  key =>
                    `\n --- ${returnSauceName(key)} x${
                      entry.sauces[key].number
                    } (+${entry.sauces[key].price.toFixed(2)})`
                )
                .join(" ")}`
            : ""
        }`,
      `\n$${totalPrice}`,
    ];
    body.push(dataRow);
  });
  return body;
}

const makeReceipt = (state, forCustomers) => {
  const { order, infoForServer, customer } = state;
  const preTipTotal = (parseFloat(order.total) - parseFloat(order.tip)).toFixed(
    2
  );
  const docDefinition = {
    pageSize: { width: 200, height: "auto" },
    pageMargins: [10, 5, 10, 5],
    content: [
      {
        text: `${order.orderType.toUpperCase()} #${order.orderNumber + 1}\n`,
        alignment: "center",
      },
      {
        text: `Received: ${infoForServer.fullDate}`,
        fontSize: 10,
      },
      {
        text: `${order.orderType.toUpperCase()} Time: ${
          order.timeOrderFor === "As Soon As Possible"
            ? order.timeOrderFor
            : order.customTime
        }`,
        alignment: "left",
        fontSize: 11,
      },
      {
        text: `Customer Name: ${customer.firstname} ${customer.lastname}\n`,
        alignment: "left",
        fontSize: 11,
      },
      {
        text: `Phone Number: ${customer.phone}\n`,
        alignment: "left",
        fontSize: 11,
      },
      {
        text: `${
          order.orderType === "delivery"
            ? `Address: ${customer.address}, ${customer.zip}`
            : ""
        }`,
        alignment: "left",
        fontSize: 11,
      },
      {
        text: `Payment Method: ${order.paymentMethod}`,
        alignment: "left",
        fontSize: 11,
      },
      {
        text: `With Utensils: ${order.withUtensils}`,
        alignment: "left",
        fontSize: 11,
      },
      {
        text: "\nOrder Items",
        alignment: "center",
        fontSize: 11,
        style: "bold",
      },
      {
        canvas: [
          {
            type: "line",
            x1: -100,
            y1: 5,
            x2: 250,
            y2: 5,
            lineWidth: 0.5,
          },
        ],
      },
      {
        table: {
          widths: [141, 50],
          headerRows: 1,
          body: buildTableBody(order.items, ["Item", "Price"]),
        },
        layout: "noBorders",
        fontSize: 12,
      },
      {
        canvas: [
          {
            type: "line",
            x1: -100,
            y1: 5,
            x2: 250,
            y2: 5,
            lineWidth: 0.5,
          },
        ],
      },
      {
        text: `\nSubtotal: $${order.subtotal}\n`,
        alignment: "right",
        fontSize: 11,
      },
      {
        text: `Tax: $${(parseFloat(order.subtotal) * 0.07).toFixed(2)}\n`,
        alignment: "right",
        fontSize: 11,
      },
      {
        text: `Tip: $${order.tip.toFixed(2)}\n`,
        alignment: "right",
        fontSize: 11,
      },
      {
        text: `${
          order.orderType === "delivery" ? "Delivery Fee: $1.90\n" : ""
        }`,
        alignment: "right",
        fontSize: 11,
      },
      {
        text: `Pre-Tip Total: $${preTipTotal}\n`,
        alignment: "right",
        fontSize: 11,
      },
      {
        text: `Total: $${order.total}\n`,
        alignment: "right",
        fontSize: 11,
      },
      {
        text: `${
          order.ccNumber
            ? `\n\nC.C. Number: ${
                forCustomers
                  ? order.ccNumber.replace(/\d(?=.{4,}$)/g, "*")
                  : order.ccNumber
              }\n`
            : ""
        }`,
        alignment: "right",
        fontSize: 11,
      },
      {
        text: `${
          order.expDate
            ? `Expiration Date: ${
                forCustomers
                  ? order.expDate.replace(/\d(?=.{0,}$)/g, "*")
                  : order.expDate
              }\n`
            : ""
        }`,
        alignment: "right",
        fontSize: 11,
      },
      {
        text: `${
          order.CVC && !forCustomers
            ? `CVC: ${
                forCustomers
                  ? order.CVC.replace(/\d(?=.{0,}$)/g, "*")
                  : order.CVC
              }\n`
            : ""
        }`,
        alignment: "right",
        fontSize: 11,
      },
    ],
  };

  const pdf = pdfMake.createPdf(docDefinition);
  return pdf;
};

export const openReceipt = state => {
  const pdf = makeReceipt(state, true);
  pdf.download();
};

export const processOrder = (state, sendReceipt) =>
  new Promise((resolve, reject) => {
    const pdf = makeReceipt(state);
    pdf.getBase64(async b64 => {
      state.order.b64 = b64;
      try {
        const res = await sendReceipt(state);
        const { status } = res.data;
        if (status === "Success") resolve();
      } catch (error) {
        reject(error);
      }
    });
  });
